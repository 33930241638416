import { HStack, Text, useBreakpointValue } from '@chakra-ui/react'
import { useLocale } from 'frontastic/contexts'
import { MyStore } from '../mystore/my-store'

export const MegaMenuDetailsBar = () => {
  const { country } = useLocale()
  const isMobile = useBreakpointValue({ base: true, lg: false })

  if (country !== 'US') {
    return null
  }

  return (
    <HStack
      maxW="container.2xl"
      w={'100%'}
      m="auto"
      px={{ base: 4, lg: 10 }}
      py={2}
      spacing={8}
      bg="brand.muted"
      justifyContent={isMobile ? 'space-between' : ''}
    >
      <HStack spacing={1} cursor="pointer">
        <MyStore viewType={'drawer'} shippingType={{ shipToStore: true }} showMyStoreTitle={true} />
      </HStack>
    </HStack>
  )
}
//TODO : Remove this comment later
