import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box, Button, HStack, Skeleton } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import { useAccountCT } from 'frontastic/contexts'
import { MegaMenuDetailsBar } from './MegaMenuDetailsBar'
import { MegaMenuItem } from './MegaMenuItem'
import { Entry } from '../hooks/useContentstack'
import { refMyStore } from '../mystore/my-store'

export interface MegaMenuProps {
  isLoading?: boolean
  items: Entry[]
}

export const MegaMenu = ({ isLoading, items }: MegaMenuProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { account } = useAccountCT()
  const router = useRouter()

  const userDetected = () => {
    utagLink([
      'link',
      {
        tealium_event: 'user_detected',
        signin_source: 'auth0',
        crm_customer_number: account?.externalId || undefined,
        customer_id: account?.accountId || undefined,
        user_loginstatus: !!account?.firstName,
      },
    ])
  }

  const triggerUtagLink = () => {
    if (router?.query?.lastAction == 'signin') {
      utagLink([
        'link',
        {
          tealium_event: 'signin',
          signin_source: 'auth0',
          crm_customer_number: account?.externalId || undefined,
          customer_id: account?.accountId || undefined,
          user_loginstatus: !!account?.firstName,
        },
      ])
    }
    if (router?.query?.lastAction == 'register') {
      utagLink([
        'link',
        {
          tealium_event: 'register',
          register_source: 'auth0',
        },
      ])
    }
  }

  useEffect(() => {
    userDetected()
    triggerUtagLink()
    const newQuery = { ...router.query }
    delete newQuery.lastAction
    router.push({ pathname: router.pathname, query: newQuery })
  }, [])

  if (isLoading) {
    return <Skeleton h="70px" />
  }

  return (
    <>
      <Box
        as="nav"
        id="mega-menu"
        aria-label="Mega Menu"
        position="relative"
        zIndex="3"
        display={{ base: 'none', lg: 'flex' }}
        flexDirection="column"
        alignItems="center"
      >
        <HStack position="relative" width="100%" justifyContent="center" alignItems="stretch" spacing={2}>
          <Button
            left={3}
            top={-3}
            position="absolute"
            _focus={{ opacity: 1 }}
            opacity={0}
            variant="link"
            size="sm"
            color="shading"
            textDecoration="underline"
            onClick={() => refMyStore.current?.focus()}
          >
            {formatMessage({ id: 'megamenu.details.skipNavigation' })}
          </Button>
          {items?.map((item, idx) => <MegaMenuItem key={`${idx}-${item?.uid}`} item={item} />)}
        </HStack>
      </Box>
      <Box width="100%" bg="brand.muted">
        <MegaMenuDetailsBar />
      </Box>
    </>
  )
}
