import { useRef } from 'react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionItem,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Link as ChakraLink,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import { useLocale } from 'frontastic/contexts'
import { MegaDrawerButton } from './MegaDrawerButton'
import { MegaDrawerItemGroup } from './MegaDrawerItemGroup'
import { MEGA_MENU_ITEM, MEGA_MENU_ITEM_GROUP } from '../contentstack/mega-menu'
import { Entry } from '../hooks/useContentstack'

interface MegaDrawerItemProps {
  item: Entry
  closeAll: () => void
  isSubItem?: boolean
}

export const MegaDrawerItem = ({ item, closeAll, isSubItem }: MegaDrawerItemProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<HTMLButtonElement>(null)
  const { formatMessage } = useFormat({ name: 'common' })

  const children = item?.children
  const hasChildren = children?.length > 0
  const { country, currency } = useLocale()
  const menuItems = []
  const menuItemGroups = []
  children?.map((item: Entry) => {
    if (item?._content_type_uid === MEGA_MENU_ITEM) {
      menuItems.push(item)
    } else if (item?._content_type_uid === MEGA_MENU_ITEM_GROUP) {
      menuItemGroups.push(item)
    }
  })

  const label = item?.label ?? ''
  const href = item?.href ?? ''

  /*
    This strategy was probably used when using DXL Header. But in R2, we are using Contentstack Header.
    Henece, we can directly use item.href
    Leaving this piece of code here for reference and future use.

    const href = getHrefFromTransformedEntryData(item) ?? item?.href ?? ''
  */

  const utagLinkEvent = (navLink, navLinkUrl) => {
    utagLink(
      [
        'link',
        {
          tealium_event: 'nav_meganav',
          nav_link: navLink,
          nav_link_url: navLinkUrl,
        },
      ],
      country || 'US',
      currency || 'USD',
    )
  }

  return (
    <>
      {hasChildren ? (
        <>
          <MegaDrawerButton
            btnRef={btnRef}
            onOpen={onOpen}
            label={label}
            isSubItem={isSubItem}
            isHiglighted={item?.highlighted ?? false}
            href={href}
            closeAll={closeAll}
          />
          <Drawer isOpen={isOpen} onClose={closeAll} placement="right" size={'full'} finalFocusRef={btnRef}>
            <DrawerOverlay />
            <DrawerContent height={'100dvh'} animation="ease-in-out" transition="ease-in-out">
              <DrawerHeader
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'3rem'}
                mb={6}
                py={2}
                px={2}
                borderBottom="1px solid"
                borderBottomColor="surface.border"
              >
                <ChevronLeftIcon fontSize={24} color={'heading'} onClick={onClose} cursor={'pointer'} />
                <Text
                  width={'100%'}
                  fontWeight="bold"
                  display={'flex'}
                  justifyContent={'center'}
                  alignContent={'center'}
                  textStyle={'heading-desktop-100'}
                >
                  {label}
                </Text>
                <DrawerCloseButton
                  style={{
                    position: 'initial',
                    width: 'md',
                    height: 'md',
                    fontSize: 12,
                  }}
                  _hover={{
                    bg: 'none',
                  }}
                />
              </DrawerHeader>
              <DrawerBody py={0} px={4}>
                <ChakraLink
                  color={'text.primary'}
                  _hover={{
                    color: 'text.primary',
                  }}
                  _focus={{
                    color: 'text.primary',
                  }}
                  _active={{
                    color: 'text.primary',
                  }}
                  onClick={() => {
                    utagLinkEvent(label, href)
                  }}
                  href={href}
                >
                  <Text
                    width={'100%'}
                    display={'flex'}
                    alignContent={'center'}
                    textStyle={{ base: 'body-75' }}
                    textDecoration="underline"
                    textDecorationColor="text.primary"
                    my={3}
                    _hover={{
                      textStyle: 'body-75',
                      color: 'text.primary',
                      textDecoration: 'underline',
                      textDecorationColor: 'text.primary',
                    }}
                    _focus={{
                      textStyle: 'body-75',
                      color: 'text.primary',
                      textDecoration: 'underline',
                      textDecorationColor: 'text.primary',
                    }}
                    _active={{
                      textStyle: 'body-75',
                      color: 'text.primary',
                      textDecoration: 'underline',
                      textDecorationColor: 'text.primary',
                    }}
                  >
                    {formatMessage({ id: 'megamenu.mobile.viewAllLabel', values: { category: label } })}
                  </Text>
                </ChakraLink>
                <Accordion allowToggle mb={'lg'} gap={2} display={'flex'} flexDirection={'column'}>
                  <MegaDrawerItemGroup data={menuItemGroups} closeAll={closeAll} />

                  {menuItems.length > 0 &&
                    menuItems.map((item: Entry) => (
                      <AccordionItem
                        key={item?.uid}
                        _hover={{
                          bg: 'none',
                        }}
                        borderTop={0}
                        borderBottom="1px solid"
                        borderBottomColor="surface.highlight"
                      >
                        <MegaDrawerItem item={item} closeAll={closeAll} isSubItem />
                      </AccordionItem>
                    ))}
                </Accordion>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <ChakraLink
          onClick={() => {
            utagLinkEvent(label, href)
          }}
          href={href}
          _hover={{
            textDecoration: 'none',
            color: 'gray',
          }}
        >
          <Text width={'100%'} display={'flex'} alignContent={'center'} textStyle={{ base: 'body-75' }} my={3}>
            {label}
          </Text>
        </ChakraLink>
      )}
    </>
  )
}
